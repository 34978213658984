import React, { useEffect } from 'react';
import './About.scss';

import { IonContent, IonPage, IonItemGroup, IonGrid, IonCol, IonRow } from '@ionic/react';
import { fetchAboutContent } from '../../api/API';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';

const About: React.FC = () => {

  const fetchContent: any = async () => {  return await  fetchAboutContent(); } //call to /api promise defined
  const dispatch = useDispatch();
  const aboutContent = useSelector((state: RootStateOrAny) => state?.aboutcontent); //retrive redux state from the store

  const addAboutContent:any = (cont:any) => {  //redux action and object defined
    return {
        type: "addedAboutOnce",
        payload: cont,
    };
};

const callAboutApi = () => {
  fetchContent().then((a:any) => { //call API promise to fetch content
    dispatch(addAboutContent(a)); //dispatch to redux reducer
  });
  return;
}

useEffect(() => {
  callAboutApi(); //call the api on page load once
}, []);

/*sole function to process all the about content items from the nosql collection api*/
const ProcessContentItems: any = () => {
  let result: any[] = [];
  let pCount: number = 0;
  aboutContent.map((data: any, i:any) => {   
        result.push( Object.values(data.content).map((paragraphs:any, index:any) => { 
        pCount++;
        return ( (data.heading=='maincontent') ? (<span key={pCount}><p>{paragraphs}</p></span>) : (index==0 && data.heading!=='maincontent') ? (<div key={pCount}><p><span className='about-heading'>{data.heading}:</span><span><>{paragraphs}</></span></p></div>) : (<span key={pCount}><p className='about-mission-details-processed'>{paragraphs}</p></span>) );
        }
      ))
  })
  return result 
};

return (
  <IonPage>
    <IonContent>
      <IonItemGroup class="form-border-all">
        <IonGrid>
          <IonRow class="ion-justify-content-center">
            <IonCol size-xs="11" size-sm="9" size-md="10" size-lg="10" size-xl="9">
              <IonRow>
              <div className='about-details'><ProcessContentItems/></div>
              </IonRow>
            </IonCol>
          </IonRow>  
        </IonGrid>       
      </IonItemGroup>
    </IonContent>
  </IonPage>
);
};

export default About;
