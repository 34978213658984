import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import './Services.scss';
import { makeStyles } from '@material-ui/core/styles';
//import IndustrialImg from '../../../public/assets/icon/industril.jpg'

//import MaUTable from "@material-ui/core/Table";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";

import { IonPage, IonContent, IonInput, IonItem, IonLabel, IonButton, IonItemGroup,
         IonModal, IonItemDivider, IonSelect, IonSelectOption,
         IonGrid, IonCol, IonRow, 
         IonIcon,
         IonCard,
         IonCardHeader,
         IonCardTitle,
         IonCardSubtitle,
         IonCardContent,
         IonList,
         IonThumbnail,
         IonHeader,
         IonToolbar,
         IonTitle,
         IonButtons,
         IonMenuButton} from '@ionic/react';
import { Box } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
//import { fetchDataTest } from '../../api/API';
import { fetchServiceContent } from '../../api/API';
import { IonReactRouter } from '@ionic/react-router';
import { Link, useHistory } from 'react-router-dom';
import { AppState, ContentContext } from '../../appcontext/ContentContext';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
//import { MyContext } from '../../appcontext/ContentContextProvider';

const Services: React.FC = () => {

  //console.log('r fc calledd');  //todo: extra zombie calls to look into and fix

  //set service accordion toggle states
 const [serviceCloud, setServiceCloud] = useState(false) as any;
 const [serviceDatabase, setServiceDatabase] = useState(false) as any;
 const [serviceScalingOpt, setserviceScalingOpt] = useState(false);
 const [serviceSoftwareArchNDev, setServiceSoftwareArchNDev] = useState(false);
 const [serviceDevopsNCicd, setServiceDevopsNCicd] = useState(false);

 const [serviceCloudHover, setServiceCloudHover] = useState(false) as any;
 const [serviceDatabaseHover, setServiceDatabaseHover] = useState(false) as any;
 const [serviceScalingOptHover, setServiceScalingOptHover] = useState(false) as any;
 const [serviceSoftwareArchNDevHover, setServiceSoftwareArchNDevHover] = useState(false) as any;
 const [serviceDevopsNCicdHover, setServiceDevopsNCicdHover] = useState(false) as any;
 

 //the expand contract +- icons based on the accordion expand collapse change
 const [customServiceCloudExpandIcon, setCustomServiceCloudExpandIcon] = useState("") as any;
 const [customServiceDatabaseExpandIcon, setCustomServiceDatabaseExpandIcon] = useState("") as any;
 const [customServiceScalingOptExpandIcon, setCustomServiceScalingOptExpandIcon] = useState("") as any;
 const [customServiceSoftwareArchNDevExpandIcon, setCustomServiceSoftwareArchNDevExpandIcon] = useState("") as any;
 const [customServiceServiceDevopsNCicdExpandIcon, setCustomServiceServiceDevopsNCicdExpandIcon] = useState("") as any;

 const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
 
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      overflowX: 'auto'
    },

    mainMessage: {
      textAlign: 'left',
      fontSize: 'large',
      fontWeight: 'lighter'

    },

    accordionSummaryStyles : {
      fontSize: 'large',
      fontWeight: 'bold',
      '&:hover': {
        color: "#0095fff2",
    }
  },

  accordionSummaryStylesExpanded : {
    fontSize: 'large',
    fontWeight: 'bold',
   color: "#0095fff2",
  },
    
  areaStyles : {
    display: 'block',
    cursor: 'pointer',
    backgroundColor: 'red'
  },

  }));

  //const { content, updateState } = useContext(ContentContext);
  //const { state, setName } = useContext(MyContext);

  const [ theContent, setTheContent ] = useState<any>([{}]);
  const fetchContent: any = async () => {  return await  fetchServiceContent(); } //call to /api promise defined

  const dispatch = useDispatch();
  const myContent = useSelector((state: RootStateOrAny) => state?.servicecontent); //retrive redux state from the store

  const addContent:any = (cont:any) => {  //redux action and object defined
      return {
          type: "addedOnce",
          payload: cont,
      };
  };

  const callApi = () => {
    fetchContent().then((a:any) => { //call API promise to fetch content
      //console.log(a);
      dispatch(addContent(a)); //dispatch to redux reducer
    });
    return;
  }

  const hadleApi = () => {
    setTheContent(myContent);
    return;
  }

  useEffect(() => {
    //console.log('API result dispatched after return from call');
    callApi(); //call the api on page load once
  }, []);

  /*
  useMemo(() => {
    console.log('use memo second call');
    hadleApi();
    //console.log('The contents at services page??');    
    //console.log(myContent)
  }, [myContent]);
*/
 
  //set the custom accordion +- expand colapse icon funtional component
  const CustomServiceExpandIcon = (serviceSector: any) => {
    return (
      <Box 
      >
      { (serviceSector) ? <div className="expandIconWrapper">-</div> : <div className="collapsIconWrapper">+</div>}
      </Box>
    );
  };

  //set the accordion onclick toggle flags
  const toggleServicecloudAccordion = () => {setServiceCloud(!serviceCloud); return; };
  const toggleServiceDatabaseAccordion = () => {setServiceDatabase(!serviceDatabase); return; };
  const toggleServiceScalingOptAccordion = () => {setserviceScalingOpt(!serviceScalingOpt); return; };
  const toggleServiceSoftwareArchNDevAccordion = () => {setServiceSoftwareArchNDev(!serviceSoftwareArchNDev); return; };
  const toggleServiceDevopsNCicdAccordion = () => {setServiceDevopsNCicd(!serviceDevopsNCicd); return; };

  //set the image area section hover flags to highlight the accordion service sections
  const toggleServicecloudAccordionHover = () => {setServiceCloudHover(!serviceCloudHover); return; };
  const toggleServiceDatabaseAccordionHover = () => {setServiceDatabaseHover(!serviceDatabaseHover); return; };
  const toggleServiceScalingOptAccordionHover = () => {setServiceScalingOptHover(!serviceScalingOptHover); return; };
  const toggleServiceSoftwareArchNDevAccordionHover = () => {setServiceSoftwareArchNDevHover(!serviceSoftwareArchNDevHover); return; };
  const toggleServiceDevopsNCicdAccordionHover = () => {setServiceDevopsNCicdHover(!serviceDevopsNCicdHover); return; };

  const classes = useStyles();
  //image with custom map clickable areas
  const CustomImgMapArea = () => {
    return (
      <div className={classes.root}>
      <img src='assets/Centripet_Services.jpg' height="100%" width="100%" className="centripet-main-img" alt="My Image" useMap="#overButtonMap"/>
      <map name="overButtonMap">
        <area onClick={toggleServicecloudAccordion} onMouseEnter={toggleServicecloudAccordionHover} onMouseLeave={toggleServicecloudAccordionHover} className={classes.areaStyles} title={serviceCloudHover ? 'API Cloud & Management' : ''} shape="rect" coords="34,44, 234, 144" alt="Page1"/>
        <area onClick={toggleServiceDatabaseAccordion} onMouseEnter={toggleServiceDatabaseAccordionHover} onMouseLeave={toggleServiceDatabaseAccordionHover} className={classes.areaStyles} title={serviceDatabaseHover ? 'Database Management' : ''} shape="rect" coords="500,44,700, 144"  alt="Page1"/>
        <area onClick={toggleServiceScalingOptAccordion} onMouseEnter={toggleServiceScalingOptAccordionHover} onMouseLeave={toggleServiceScalingOptAccordionHover} className={classes.areaStyles} title={serviceScalingOptHover ? 'Scaling & Optimization' : ''} shape="rect" coords="34,344,234, 444"  alt="Page1"/>
        <area onClick={toggleServiceSoftwareArchNDevAccordion} onMouseEnter={toggleServiceSoftwareArchNDevAccordionHover} onMouseLeave={toggleServiceSoftwareArchNDevAccordionHover} className={classes.areaStyles} title={serviceSoftwareArchNDevHover ? 'Software Architechture & Dev' : ''} shape="rect" coords="500,344,700, 444"  alt="Page1"/>
        <area onClick={toggleServiceDevopsNCicdAccordion} onMouseEnter={toggleServiceDevopsNCicdAccordionHover} onMouseLeave={toggleServiceDevopsNCicdAccordionHover} className={classes.areaStyles} title={serviceDevopsNCicdHover ? 'Devops & CICD' : ''} shape="rect" coords="260,444,380, 564"  alt="Page1"/>
      </map> 
    </div>
    );
  };

  
  const returnExpanded = (contentObj:any) => {
     
    switch(contentObj.heading){
      case "API and Cloud management":
        return serviceCloud;
      case "Database engineering, maintenance, and optimization":
        return serviceDatabase;  
      case "Scaling and Optimization":
        return serviceScalingOpt;  
      case "Software Architecture and Development":
        return serviceSoftwareArchNDev;
      case "Devops and CICD":
          return serviceDevopsNCicd;   
      default: return false;  
    }
    
  };

  const returnExpandedOnHover = (contentObj:any) => {
     
    switch(contentObj.heading){
      case "API and Cloud management":
        return serviceCloudHover;
      case "Database engineering, maintenance, and optimization":
        return serviceDatabaseHover;  
      case "Scaling and Optimization":
        return serviceScalingOptHover;  
      case "Software Architecture and Development":
        return serviceSoftwareArchNDevHover;   
      case "Devops and CICD":
          return serviceDevopsNCicdHover;     
      default: return false;  
    }
    
  };

  const setChangeHandler = (contentObj:any) => {
     
    switch(contentObj.heading){
      case "API and Cloud management":
        return handleServiceCloudChange;
      case "Database engineering, maintenance, and optimization":
        return handleServiceDatabaseChange;  
      case "Scaling and Optimization":
        return handleServiceScalingOptChange; 
      case "Software Architecture and Development":
          return handleServiceSoftwareArchNDevChange;  
      case "Devops and CICD":
            return handleServiceDevopsNCicdChange;        
      default: return undefined;  
    }
    
  };

  const setcustomServiceExpandIcon = (contentObj:any) => {
     
    switch(contentObj.heading){
      case "API and Cloud management":
        return customServiceCloudExpandIcon;
      case "Database engineering, maintenance, and optimization":
        return customServiceDatabaseExpandIcon;  
      case "Scaling and Optimization":
        return customServiceScalingOptExpandIcon;
      case "Software Architecture and Development":
        return customServiceSoftwareArchNDevExpandIcon;   
      case "Devops and CICD":
          return customServiceServiceDevopsNCicdExpandIcon;     
      default: return "";  
    }
    
  };

  const processAccordionItems = () => {
     return ( 
      myContent.map((contentObj: any, index: number) =>{
          return (
            <Accordion key={index} expanded={returnExpanded(contentObj)} onChange={setChangeHandler(contentObj)}>
            <AccordionSummary className={(returnExpanded(contentObj)) || (returnExpandedOnHover(contentObj)) ? classes.accordionSummaryStylesExpanded : classes.accordionSummaryStyles} expandIcon={setcustomServiceExpandIcon(contentObj)}>{contentObj.heading}</AccordionSummary>
              <AccordionDetails>
                {contentObj.content}
              </AccordionDetails>
            </Accordion>
          )
        })
    )
  };

  const AccordionComponent = () => {
    const ProcessedAccordionItems = useMemo(() => processAccordionItems, []);
      return (
        <div className={classes.mainMessage}>
        <ProcessedAccordionItems />
        </div>
      );
  }

  const ServiceSectionDesktop = () => {
    return (
      <>
     <IonCol>
          <CustomImgMapArea />
          </IonCol>
          <IonCol>
            <AccordionComponent />      
          </IonCol>
      </>
    );
  };

  const history = useHistory();
  const backClickHandler = ()  => { setServiceCloud(!serviceCloud); return; };
  const forwardClickHandler = (e:any, to:any, pageSection: any)  => { 
    e.preventDefault(); 
     history.push({
         pathname: to,
         state: pageSection 
    });
    return; 
  };

  const returnPngSourceItems = (contentObj:any) => {
    switch(contentObj.heading){
      case "API and Cloud management":
        return "assets/Individual_services/cloud.png";
      case "Database engineering, maintenance, and optimization":
        return "assets/Individual_services/databases.png";  
      case "Scaling and Optimization":
        return "assets/Individual_services/scaling.png";  
      case "Software Architecture and Development":
        return "assets/Individual_services/software_arch_n_dev.png";
      case "Devops and CICD":
          return "assets/Individual_services/devops_n_cicd.png";   
      default: return "";  
    }
  };

  const ProcessMobileDetailItems = () => {
    //console.log('whats in my content???');
    //console.log(myContent);
    return ( 
      myContent.map((contentObj: any, index: number) =>{
        return (
             <IonList no-lines key={index}>
             <IonItem class="no-border">
              <IonThumbnail class="thumbnail-style" slot="start">
                <IonButton fill="clear" class="button-style" color="#051a0d" onClick={(e) => forwardClickHandler(e,"/serviceDetails",contentObj.mobilecontentkey)}>
                  <img alt={`centripet${contentObj.heading}`} src={returnPngSourceItems(contentObj)} />
                </IonButton>  
              </IonThumbnail>
            <IonLabel class="service-label-mobile-style">{contentObj.heading}</IonLabel>
            </IonItem>
            </IonList> 
        )
      })
   )
 };

  const ServiceSectionMobile = () => {
    return ( 
      <>
      <IonCol>
      <IonHeader className="ion-no-border" translucent>
          <IonToolbar>
            <IonTitle class="no-border">Services</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonReactRouter>
        < ProcessMobileDetailItems />
        </IonReactRouter>
      </IonCol>
      </>  
    )
  };

  //handle the accordion tab changes
  const handleServiceCloudChange = ()  => { setServiceCloud(!serviceCloud); return; };
  const handleServiceDatabaseChange = ()  => { setServiceDatabase(!serviceDatabase); return; };
  const handleServiceScalingOptChange = ()  => { setserviceScalingOpt(!serviceScalingOpt); return; };
  const handleServiceSoftwareArchNDevChange = ()  => { setServiceSoftwareArchNDev(!serviceSoftwareArchNDev); return; };
  const handleServiceDevopsNCicdChange = ()  => { setServiceDevopsNCicd(!serviceDevopsNCicd); return; };

  //set the expand contract +- icons based on the accordion expand colapse change
  useMemo(() => {
     setCustomServiceCloudExpandIcon(CustomServiceExpandIcon(serviceCloud));
   }, [serviceCloud]);

  useMemo(() => { setCustomServiceDatabaseExpandIcon(CustomServiceExpandIcon(serviceDatabase)); }, [serviceDatabase]);
  useMemo(() => { setCustomServiceScalingOptExpandIcon(CustomServiceExpandIcon(serviceScalingOpt)); }, [serviceScalingOpt]);
  useMemo(() => { setCustomServiceSoftwareArchNDevExpandIcon(CustomServiceExpandIcon(serviceSoftwareArchNDev)); }, [serviceSoftwareArchNDev]);
  useMemo(() => { setCustomServiceServiceDevopsNCicdExpandIcon(CustomServiceExpandIcon(serviceDevopsNCicd)); }, [serviceDevopsNCicd]);

  return (
      <IonPage>
        <IonContent>
          <IonItemGroup class="form-border-all">
          <IonGrid>
          <IonRow class="ion-justify-content-center">
          <IonCol size-xs="13" size-sm="9" size-md="10" size-lg="10" size-xl="9">
            <IonRow>
              {!isMobile ? <ServiceSectionDesktop /> : <ServiceSectionMobile />}
            </IonRow>
            </IonCol>
            </IonRow>
              </IonGrid>
          </IonItemGroup>
        </IonContent>
      </IonPage>
    );
  };

export default Services;
