import axios from 'axios';

//fetch data from node js express
  export const fetchDataTest = async () => {

    const promise  = axios.get("/api/v3/reactdataparse",{
      headers: new Headers({ "Content-Type": "application/json" })
    });
    const dataPromise = promise.then((response:any) => response.data)
  
    return dataPromise;

  } 

  //fetch data from spring
  export const fetchServiceContent = async () => {     //mongodb based API

    const promise  = axios.get("/cntmgo/servicesContent",{
      headers: new Headers({ "Content-Type": "application/json" })
    });
    const dataPromise = promise.then(
      (response:any) => response.data.ServiceContent
    )
    return dataPromise;
  
  } 

  export const fetchAboutContent = async () => {     //mongodb based API

    const promise  = axios.get("/cntmgo/aboutContent",{
      headers: new Headers({ "Content-Type": "application/json" })
    });
    const dataPromise = promise.then(
      (response:any) => response.data.AboutContent
    )
    return dataPromise;
  
  }

  export const fetchPrivacyPolicyContent = async () => {     //mongodb based API

    const promise  = axios.get("/cntmgo/privacypolicyContent",{
      headers: new Headers({ "Content-Type": "application/json" })
    });
    const dataPromise = promise.then(
      (response:any) => response.data.PrivacyPolicyContent
    )
    return dataPromise;
  
  }

  export const fetchTermsOfUseContent = async () => {     //mongodb based API

    const promise  = axios.get("/cntmgo/termsofuseContent",{
      headers: new Headers({ "Content-Type": "application/json" })
    });
    const dataPromise = promise.then(
      (response:any) => response.data.TermsOfUseContent
    )
    return dataPromise;
  
  }

  /*
  export const fetchAboutContent = async () => {     //JPA mysql based API

    const promise  = axios.get("/cnt/aboutContent",{
      headers: new Headers({ "Content-Type": "application/json" })
    });
    const dataPromise = promise.then(
      (response:any) => response.data.AboutContent
    )
    return dataPromise;
  
  } 
  */  

  /*
  //second API for implementation example
  export const fetchServiceContent = async () => {     //JPA mysql based API

    const promise  = axios.get("/cnt/servicesContent",{
      headers: new Headers({ "Content-Type": "application/json" })
    });
    const dataPromise = promise.then(
      (response:any) => response.data.ServiceContent
    )
    return dataPromise;
  
  } 
  */  

  //update data Post example
  /*
  export const updateServiceContent = async (data: any) => {     //mongodb or JPA based API

    const promise  = axios.post("/cntmgo/servicesContent", data , {
      headers: new Headers({ "Content-Type": "application/json" })
    });
    const dataPromise = promise.then(
      (response:any) => response.data.ServiceContent
    )
    return dataPromise;
  
  } 
  */  