import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import './Home.scss';
import { makeStyles } from '@material-ui/core/styles';

import { IonPage, IonContent, IonInput, IonItem, IonLabel, IonButton, IonItemGroup,
         IonModal, IonItemDivider, IonSelect, IonSelectOption,
         IonGrid, IonCol, IonRow, 
         IonCard} from '@ionic/react';
import { ContentContext } from '../../appcontext/ContentContext';
import { ServicePageReducer } from '../../appReducer/ServicePageReducer';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';

const Home: React.FC = () => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
  const [ formErrors, setFormErrors ] = useState<any>({
     message: ""
  });

  const [ theContent, setTheContent ] = useState<any>({});
  const useStyles = makeStyles((theme) => ({
    root: {
      overflowX: 'auto',
      overflowY: 'auto',
      maxWidth: '100%',
      maxHeight: '100%',
      /*position: 'relative'*/
    },

    centripetMainImgMobile: {
      padding: '0px 1px 0px 1px !important',
      borderRadius: '10px 10px10px 10px',
      /*marginLeft: '270px',*/
      margin: 'auto',
      /*width: '300px',*/
      /*transform: 'translateX(-50%, -50%)'*/
    },

    mainMessage_a1: {
      textAlign: 'center',
      fontSize: '-webkit-xxx-large',
      color: '#fff',
      fontWeight: 'lighter',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },

    mainMessageMobile_a1: {
      textAlign: 'center',
      fontSize: '-webkit-xx-large',
      color: '#fff',
      fontWeight: 'bold',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '70%',
      transform: 'translate(-50%, -50%)',
      wordBreak: 'normal',
      wordWrap: 'normal',
    },

    mainMessage_b2: {
      textAlign: 'center',
      fontSize: '-webkit-xxx-large',
      color: '#fff',
      fontWeight: 'bold',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },

    mainMessageMobile_b2: {
      textAlign: 'center',
      fontSize: '-webkit-xx-large',
      color: '#fff',
      fontWeight: 'bold',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      wordBreak: 'normal',
      wordWrap: 'normal'

    },

    mainMessage_c2: {
      textAlign: 'center',
      fontSize: '-webkit-xxx-large',
      color: '#051a0d !important',
      fontWeight: 'bold',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },

    mainMessageMobile_c2: {
      textAlign: 'center',
      fontSize: '-webkit-xx-large',
      color: '#fff !important',
      fontWeight: 'bold',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '70%',
      transform: 'translate(-50%, -50%)',
      wordBreak: 'normal',
      wordWrap: 'normal'

    },

  }));
  
    const MainImage_a1 = () => {
      return (
        <>
        <div className={classes.root}>
           <img src='assets/centripetMain.jpg' height="100%" width="100%"  alt="My Image" />
        </div>
        <div className={classes.mainMessage_a1}>
          <p>Central to efficiency and customer satisfaction</p>
        </div>
        </>
      );
    };

    const MainImage_a2 = () => {
      return (
        <>
        <div className={classes.root}>
           <img src='assets/centripetMain.jpg' height="100%" width="100%" alt="My Image" />
        </div>
        <div className={classes.mainMessageMobile_a1}>
          <p>Central to efficiency and customer satisfaction</p>
        </div>
        </>
      );
    };

    const MainImage_b1 = () => {
      return (
        <>
        <div className={classes.root}>
           <img src='assets/centripetMain2.jpg' height="100%" width="100%" alt="My Image" />
        </div>
        <div className={classes.mainMessage_b2}>
          <p>Reach us at sales@centripet.com</p>
        </div>
        </>
      );
    };

    const MainImage_b2 = () => {
      return (
        <>
        <div className={classes.root}>
           <img src='assets/centripetMain2.jpg'  height="100%" width="100%" alt="My Image" />
        </div>
        <div className={classes.mainMessageMobile_b2}>
          <p>Reach us at sales@centripet.com</p>
        </div>
        </>
      );
    };

    const MainImage_c1 = () => {
      return (
        <>
        <div className={classes.root}>
           <img src='assets/centripetMain3.jpg' height="100%" width="100%" alt="My Image" />
        </div>
        <div className={classes.mainMessage_c2}>
          <p>Keeping you connected</p>
        </div>
        </>
      );
    };

    const MainImage_c2 = () => {
      return (
        <>
        <div className={classes.root}>
           <img src='assets/centripetMain3.jpg'  height="100%" width="100%" alt="My Image" />
        </div>
        <div className={classes.mainMessageMobile_c2}>
          <p>Keeping you connected</p>
        </div>
        </>
      );
    }

    const classes = useStyles();

    /*
    //POC to show the context state of content 
    const { servicecontent, updateState } = useContext(ContentContext);
    console.log("content at home page????");
    console.log(servicecontent);
  */
  
    /*
    //POC to show the Redux store state
    const myContent = useSelector((state: RootStateOrAny) => state?.servicecontent);
    const aboutContent = useSelector((state: RootStateOrAny) => state?.aboutcontent);
    useMemo(() => {
    console.log('redux state at home page');
    console.log(myContent);
    console.log(aboutContent);
    }, [myContent,aboutContent]);
    */
  
      return (
        <IonPage>
        
          <IonContent>
            <IonItemGroup class={(!isMobile)? "table-border-bottom-none":"table-border-bottom-for-mobile"}>

            <IonGrid>
              <IonRow class="ion-justify-content-center">

                {!isMobile ?
                <IonCol size-xs="33.6" size-sm="40" size-md="35" size-lg="40" size-xl="12">
                  <MainImage_a1 />
                </IonCol> :
                <IonCard>
                 <MainImage_a2 />
                </IonCard>
               }
                
              </IonRow>

              <IonRow class="ion-justify-content-center">
                {!isMobile ?
                <IonCol size-xs="33.6" size-sm="40" size-md="35" size-lg="40" size-xl="12">
                  <MainImage_b1 />
                </IonCol> :
                <IonCard>
                 <MainImage_b2 />
                </IonCard>
                 }
              </IonRow>

              <IonRow class="ion-justify-content-center">
                {!isMobile ?
                <IonCol size-xs="33.6" size-sm="40" size-md="35" size-lg="40" size-xl="12">
                  <MainImage_c1 />
                </IonCol> :
                <IonCard>
                 <MainImage_c2 />
                </IonCard>
                 }
              </IonRow>

               </IonGrid>
            </IonItemGroup>
          </IonContent>
        </IonPage>
      );
    };

export default Home;
